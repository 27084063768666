import React from "react"
import {
  Flex,
  Heading,
  Text,
  Box,
  ListItem,
  OrderedList,
} from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
  },
  "inner-box": {
    margin: "0 auto",
    my: { base: "20px", md: "20px", lg: "60px" },
    width: "100%",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    width: { base: "100%", md: "100%", lg: "576px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "flex-start", md: "flex-start", lg: "flex-start" },
    mr: { base: "0px", md: "0px", lg: "40px" },
  },
  "right-box": {
    mr: { base: "0", md: "0", lg: "104px" },
    width: { base: "100%", md: "100%", lg: "360px" },
  },
  "heading-box": {
    width: "100%",
    mt: { base: "28px", md: "28px", lg: "0px" },
  },
  heading: {
    fontSize: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: 700,
    lineHeight: { base: "40px", md: "40px", lg: "48px" },
    textAlign: "center",
    letterSpacing: "0em",
  },
  "sub-heading": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D3748",
    alignItems: "center",
  },
  "allstar-text": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#129459",
  },
  hero: {
    mt: "24px",
    width: "140px",
  },
}

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Box sx={styles["heading-box"]}>
          <Heading sx={styles["sub-heading"]} mt="12px">
            Congratulations again on being named to the elite list of Healthcare
            Research All-Stars for 2024!
          </Heading>
        </Box>

        <Text sx={styles.text} mt="24px">
          You can copy/paste from the templates below to publicize your status
          as a Healthcare Research All-Star via social media posts. Please be
          sure to:
        </Text>
        <OrderedList sx={styles.text} mt="24px">
          <ListItem>
            Replace “<strong>[Specialty]</strong>” with the appropriate
            specialty related to your All-Star status.
          </ListItem>
          <ListItem>
            Remove hashtags that do not apply to your specialty.
          </ListItem>
          <ListItem>
            Keep <strong>#HealthcareResearchAll-Stars</strong> as the first
            hashtag
          </ListItem>
        </OrderedList>
      </Flex>
      <Box sx={styles["right-box"]}>
        <Flex flexDirection="column" alignItems="center">
          <Heading as="h2" sx={styles["allstar-text"]}>
            Healthcare Research All-Stars
          </Heading>
          <Heading as="h1" sx={styles.heading} mt="8px">
            Social Media Templates
          </Heading>
          <Box sx={styles.hero}>
            <StaticImage
              src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
              alt="Healthcare Research All-Stars Logo"
              placeholder="blurred"
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Box>
)

export default SectionHero
