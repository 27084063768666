import React from "react"

import { Text, Link } from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"
import { AGH_NETWORK, ALL_STAR_PAGES_URL } from "../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    bg: "#F4F7FF",
    justifyContent: "center",
  },
  content: {
    my: { base: "24px", md: "24px", lg: "60px" },
    maxWidth: "1080px",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
}

export const SOCIAL_MEDIA_CONTENTS = {
  hospitalMessageForLinkedIn: (specialty = "[specialty]") => {
    return (
      <Text sx={styles.text} mt="20px">
        We are proud to announce our inclusion as one of the{" "}
        <strong>{specialty}</strong> Research All-Stars for 2024! This honor is
        in recognition of the quantity and quality of the{" "}
        <strong>{specialty}</strong> research authored and published by our
        surgeons. The Healthcare Research All-Star lists are compiled by{" "}
        <Link color="#3399FF" isExternal href={AGH_NETWORK.LINKED_IN}>
          @Avant-garde Health
        </Link>{" "}
        and include only the top 5% of hospitals and surgeons publishing
        leading-edge research in <strong>{specialty}</strong> care. You can
        review the full lists{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.HOME}
        >
          HERE
        </Link>
        .
      </Text>
    )
  },

  surgeonMessageForLinkedIn: (specialty = "[specialty]") => {
    return (
      <Text sx={styles.text} mt="20px">
        I am honored to announce my inclusion as one of the{" "}
        <strong>{specialty}</strong> Research All-Stars for 2024! This honor is
        in recognition of the quantity and quality of the{" "}
        <strong>{specialty}</strong> research i've co-authored and published.
        The Healthcare Research All-Star lists are compiled by{" "}
        <Link color="#3399FF" isExternal href={AGH_NETWORK.LINKED_IN}>
          @Avant-garde Health
        </Link>{" "}
        and include only the top 5% of hospitals and surgeons publishing
        leading-edge research in <strong>{specialty}</strong> care. You can
        review the full lists{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.HOME}
        >
          HERE
        </Link>
        .
      </Text>
    )
  },
  hospitalMessageForTwitter: (specialty = "[specialty]") => {
    return (
      <>
        <Text sx={styles.text} mt="20px">
          We're excited to share that we've been named a 2024{" "}
          <strong>{specialty}</strong> Research All-Star! This distinction
          acknowledges the extensive and high-quality research our surgeons have
          published. The <strong>#HealthcareResearchAllStars</strong> lists,
          curated by{" "}
          <Link color="#3399FF" isExternal href={AGH_NETWORK.TWITTER}>
            @Avant-garde Health
          </Link>{" "}
          , spotlight only the most distinguished in the field. [1/2]
        </Text>
        <Text sx={styles.text} mt="12px">
          [2/2] The lists include only the top 5% of hospitals and surgeons
          publishing leading-edge research in <strong>{specialty}</strong> care.
          You can review the full lists{" "}
          <Link
            sx={styles.link}
            as={GatsbyLink}
            to={"/" + ALL_STAR_PAGES_URL.HOME}
          >
            HERE
          </Link>
          .
        </Text>
      </>
    )
  },

  surgeonMessageForTwitter: (specialty = "[specialty]") => {
    return (
      <>
        <Text sx={styles.text} mt="20px">
          I'm honored to share my recognition as a 2024{" "}
          <strong>{specialty}</strong> Research All-Star! This accolade
          celebrates the significant research contributions I've made through
          co-authored publications. The{" "}
          <strong>#HealthcareResearchAllStars</strong> lists, curated by{" "}
          <Link color="#3399FF" isExternal href={AGH_NETWORK.TWITTER}>
            @Avant-garde Health
          </Link>
          , [1/2]
        </Text>
        <Text sx={styles.text} mt="12px">
          [2/2] feature only the top 5% of hospitals and surgeons whose research
          defines the forefront of <strong>{specialty}</strong> care. Explore
          the comprehensive lists and find more details{" "}
          <Link
            sx={styles.link}
            as={GatsbyLink}
            to={"/" + ALL_STAR_PAGES_URL.HOME}
          >
            HERE
          </Link>
          .
        </Text>
      </>
    )
  },

  hospitalMessageForFacebook: (specialty = "[specialty]") => {
    return (
      <Text sx={styles.text} mt="20px">
        We're honored to announce our designation as a 2024{" "}
        <strong>{specialty}</strong> Research All-Star! This prestigious
        recognition reflects the extensive and high-caliber research our
        surgeons have produced. Compiled by{" "}
        <Link color="#3399FF" isExternal href={AGH_NETWORK.FACEBOOK}>
          @Avant-garde Health
        </Link>
        , the Healthcare Research All-Star lists spotlight only the top 5% of
        hospitals and surgeons at the forefront of <strong>{specialty}</strong>{" "}
        care research. Discover our ranking and explore the full lists{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.HOME}
        >
          HERE
        </Link>
        .
      </Text>
    )
  },

  surgeonMessageForFacebook: (specialty = "[specialty]") => {
    return (
      <Text sx={styles.text} mt="20px">
        I'm honored to share that I have been recognized as a 2024{" "}
        <strong>{specialty}</strong> Research All-Star! This distinction
        acknowledges the substantial and high-quality research contributions
        I've made through co-authored publications. Curated by{" "}
        <Link color="#3399FF" isExternal href={AGH_NETWORK.FACEBOOK}>
          @Avant-garde Health
        </Link>{" "}
        , the Healthcare Research All-Star lists feature exclusively the top 5%
        of hospitals and surgeons who are leading the way in innovative research
        within <strong>{specialty}</strong> care. Explore the comprehensive
        lists and learn more about this achievement{" "}
        <Link
          sx={styles.link}
          as={GatsbyLink}
          to={"/" + ALL_STAR_PAGES_URL.HOME}
        >
          HERE
        </Link>
        .
      </Text>
    )
  },
}
