import React from "react"
import { Heading, Text, Box, Link } from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    bg: "#FFFFFF",
    p: { base: "20px", md: "36px", lg: "36px" },
    width: "100%",
    boxShadow: "0px 8px 12px 0px #2D37480D",
    borderRadius: "8px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
  hero: {
    mt: "20px",
    width: "120px",
  },
}

const TemplateItem = ({ title = "", onExampleClick = () => {}, children }) => {
  return (
    <Box sx={styles.root}>
      <Heading sx={styles.heading}>{title}</Heading>
      <Box mt="4px">
        <Link sx={styles.link} onClick={onExampleClick}>
          See examples
        </Link>
      </Box>

      <Box>{children}</Box>

      <Text sx={styles.text} mt="20px">
        <strong>
          #HealthcareResearchAllStars #oncology, #urology, #gastrointestinal,
          #otolaryngology, #vascularsurgery, #vascular, #cardiothoracicsurgery,
          #neurosurgery, #spine, #spinesurgery, #orthopedicsurgery,
          #orthopedicsurgeon, #orthopedics, #handsurgery, #shouldersurgery,
          #footsurgery, #kneesurgery, #hipsurgery
        </strong>
      </Text>

      <Box sx={styles.hero}>
        <StaticImage
          src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
          alt="physician badge"
          placeholder="blurred"
        />
      </Box>
    </Box>
  )
}

export default TemplateItem
