import React from "react"

import Layout from "../components/allstar/layout"
import SectionHero from "../components/allstar/social-media-templates/section-hero"
import SectionBody from "../components/allstar/social-media-templates/section-body"

const SocialMediaTemplatesPage = props => {
  return (
    <Layout
      location={props.location}
      title={"Social Media Templates"}
      isResourceSite
    >
      <SectionHero />
      <SectionBody />
    </Layout>
  )
}

export default SocialMediaTemplatesPage
