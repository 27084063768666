import React from "react"
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
  Heading,
} from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"

import { SOCIAL_MEDIA_CONTENTS } from "./social-media-contents"

const styles = {
  root: {
    width: "100%",
  },
  container: {
    my: "60px",
    mx: { base: "16px", md: "60px", lg: "60px" },
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D3748",
    alignItems: "center",
    textAlign: "left",
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    color: "#2D3748",
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  example: {
    p: { base: "20px", md: "36px", lg: "36px" },
    w: "100%",
    borderRadius: "8px",
    border: "1px solid #D9DCE6",
  },
  hero: {
    mt: "20px",
    width: "120px",
  },
}

const ExampleDrawer = ({
  onClose,
  isOpen,
  selectedPlatform = "",
  selectedEntity = "",
  selectedContent = "hospitalMessageForLinkedIn",
}) => {
  return (
    <Box sx={styles.root}>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="0px">
            <Box sx={styles.container}>
              <Heading sx={styles.heading}>
                {selectedPlatform} template example for {selectedEntity}
              </Heading>

              <Box sx={styles.example} mt="20px">
                <Text sx={styles.title}>Example for General Surgery </Text>
                {SOCIAL_MEDIA_CONTENTS[selectedContent]("General Surgery")}

                <Text sx={styles.text} mt="20px">
                  <strong>
                    #HealthcareResearchAllStars #generalsurgery #healthcare
                    #avantgardehealth
                  </strong>
                </Text>

                <Box sx={styles.hero}>
                  <StaticImage
                    src="../../../images/allstar/badges/Research All-Stars 2024 - General Surgery.png"
                    alt="Research All-Stars Badge"
                    placeholder="blurred"
                  />
                </Box>
              </Box>

              <Box sx={styles.example} mt="32px">
                <Text sx={styles.title}>Example for Orthopedic Surgery </Text>
                {SOCIAL_MEDIA_CONTENTS[selectedContent]("Orthopedic Surgery")}

                <Text sx={styles.text} mt="20px">
                  <strong>
                    #HealthcareResearchAllStars #orthopedicsurgery #healthcare
                    #avantgardehealth
                  </strong>
                </Text>

                <Box sx={styles.hero}>
                  <StaticImage
                    src="../../../images/allstar/badges/Research All-Stars 2024 - Orthopedic Surgery.png"
                    alt="Research All-Stars Badge"
                    placeholder="blurred"
                  />
                </Box>
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default ExampleDrawer
