import React from "react"

import { Box, Flex, Text, Link, Grid, useDisclosure } from "@chakra-ui/react"

import TemplateItem from "./template-item"
import ExampleDrawer from "./example-drawer"

import { SOCIAL_MEDIA_CONTENTS } from "./social-media-contents"

import { ALL_STAR_MAIL_LINK } from "../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    bg: "#F4F7FF",
    justifyContent: "center",
  },
  content: {
    my: { base: "24px", md: "24px", lg: "60px" },
    maxWidth: "1080px",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
}

const SectionBody = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedPlatform, setSelectedPlatform] = React.useState()
  const [selectedEntity, setSelectedEntity] = React.useState()
  const [selectedContent, setSelectedContent] = React.useState()

  const handleExampleLinkClick = (platform, entity, content) => {
    setSelectedPlatform(platform)
    setSelectedEntity(entity)
    setSelectedContent(content)
    onOpen()
  }

  return (
    <Flex sx={styles.root}>
      <Box sx={styles.content}>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
            lg: "repeat(2, 1fr)",
          }}
          columnGap="48px"
          rowGap={{ base: "20px", md: "20px", lg: "40px" }}
        >
          <TemplateItem
            title="LinkedIn Template for Hospitals"
            onExampleClick={() => {
              handleExampleLinkClick(
                "LinkedIn",
                "Hospitals",
                "hospitalMessageForLinkedIn"
              )
            }}
          >
            {SOCIAL_MEDIA_CONTENTS.hospitalMessageForLinkedIn()}
          </TemplateItem>
          <TemplateItem
            title="LinkedIn Template for Surgeons"
            onExampleClick={() => {
              handleExampleLinkClick(
                "LinkedIn",
                "Surgeons",
                "surgeonMessageForLinkedIn"
              )
            }}
          >
            {SOCIAL_MEDIA_CONTENTS.surgeonMessageForLinkedIn()}
          </TemplateItem>

          <TemplateItem
            title="Twitter/X Template for Hospitals"
            onExampleClick={() => {
              handleExampleLinkClick(
                "Twitter/X",
                "Hospitals",
                "hospitalMessageForTwitter"
              )
            }}
          >
            {SOCIAL_MEDIA_CONTENTS.hospitalMessageForTwitter()}
          </TemplateItem>
          <TemplateItem
            title="Twitter/X Template for Surgeons"
            onExampleClick={() => {
              handleExampleLinkClick(
                "Twitter/X",
                "Surgeons",
                "surgeonMessageForTwitter"
              )
            }}
          >
            {SOCIAL_MEDIA_CONTENTS.surgeonMessageForTwitter()}
          </TemplateItem>

          <TemplateItem
            title="Facebook Template for Hospitals"
            onExampleClick={() => {
              handleExampleLinkClick(
                "Facebook",
                "Hospitals",
                "hospitalMessageForFacebook"
              )
            }}
          >
            {SOCIAL_MEDIA_CONTENTS.hospitalMessageForFacebook()}
          </TemplateItem>
          <TemplateItem
            title="Facebook Template for Surgeons"
            onExampleClick={() => {
              handleExampleLinkClick(
                "Facebook",
                "Surgeons",
                "surgeonMessageForFacebook"
              )
            }}
          >
            {SOCIAL_MEDIA_CONTENTS.surgeonMessageForFacebook()}
          </TemplateItem>
        </Grid>

        <Text sx={styles.text} mt="40px" maxW="590px">
          If you have any questions or difficulty with the social media
          template, please contact us at{" "}
          <Link sx={styles.link} href={ALL_STAR_MAIL_LINK}>
            Research.All-Stars@avantgardehealth.com
          </Link>
          .
        </Text>

        <ExampleDrawer
          isOpen={isOpen}
          onClose={onClose}
          selectedPlatform={selectedPlatform}
          selectedEntity={selectedEntity}
          selectedContent={selectedContent}
        />
      </Box>
    </Flex>
  )
}

export default SectionBody
